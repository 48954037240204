import React from "react";
import styles from "./privacy-policy.module.scss";
import Fade from 'react-reveal/Fade';

const PrivacyPolicy = () => (
  <Fade>
    <p>CELLR and Cellar Ventures, Inc. takes your security and the privacy very seriously.
      We want to be open and clear about how we collect and store personal information.
      Personal Information is information that relates to an identified or identifiable natural person.</p>
    <p>How we use your personal information</p>
    <ul className={styles.info}>
      <li>To keep CELLR users posted on latest news, product announcements and software updates.</li>
      <li>To identify subscribers to enable log in and to synchronize information across different devices.</li>
      <li>To help operate and improve CELLR services such as anti-fraud protection and data analysis and research into trends.</li>
      <li>CELLR also de-identifies personal information to create aggregated anonymous
        reports on community inventory trends, consumption trends, and similar reports.</li>
      <li>CELLR may share information in the future with partners to provide technical services and for
        enabling and conducting customer research. These companies are obligated to protect your
        information and are forbiden from using information collected in any other way.
        Future providers may support services in the following areas.</li>
    </ul>
    <ul className={styles.areas}>
      <li>Customer feedback</li>
      <li>Mailing lists</li>
      <li>Payment gateway providers</li>
      <li>Technical support</li>
    </ul>

    <p>How we collect personal information</p>
    <ul className={styles.info}>
      <li>We collect information that you give us:</li>
    </ul>
    <ul className={styles.areas}>
      <li>CELLR accounts require you to sign up. Subscribers are asked to provide name, email address and to create a password.</li>
      <li>CELLR User Profiles can be designated as non-public and kept private.</li>
    </ul>

    <ul className={styles.info}>
      <li>We collect information from interactions with our services:</li>
    </ul>
    <ul className={styles.areas}>
      <li>Servers automatically collect information such as search queries, clickthroughs to shops, and your wine ratings and personal tasting notes.</li>
      <li>Device specific information is collected such as activity, browser type, language, the date and time of your request and referral URL.</li>
      <li>Cookies or similar technologies such as mobile device identifiers and SDK integrations help recognise you when you log in or return.</li>
      <li>Internet Protocol address (IP) and network information.</li>
      <li>Device-specific information or unique device identifiers (UUID) identify hardware model, operating system version and mobile network information.</li>
      <li>Storage information on your device collects your recent searches, ratings and label images.</li>
      <li>Location information collected by various technologies such as IP address or GPS and linked to your device to provide services such as Find Nearby Stores.</li>
      <li>Unique application numbers about your installation such as, the operating system type and application version for automatic updates.</li>
    </ul>

    <ul className={styles.info}>
      <li>How to remove your personal information:</li>
    </ul>
    <ul className={styles.areas}>
      <li>If you do not want to be on any future mailing lists, you will have the ability to opt out anytime by clicking the unsubscribe link in a CELLR email.</li>
      <li>Subscribers can manage preferences in My Account.</li>
      <li>If you do not consent to the use of cookies, please block or disable these in your browser settings or the settings within the mobile apps.</li>
    </ul>

  </Fade>
)

export default PrivacyPolicy;

