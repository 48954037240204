import React from "react";
import styles from "./acceptable-use-policy.module.scss"
import Fade from 'react-reveal/Fade';

const AcceptableUsePolicy = () => (
  <Fade>
    <p>Acceptable Use Policy</p>
    <ul className={styles.info}>
        <li>
            CELLR’s Acceptable Use Policy applies to all users of CELLR. By using CELLR you are agreeing to abide by this policy. You are independently responsible for complying with all relevant laws in your use of CELLR.
        </li>
        <li>
            We prohibit anything that may be illegal or inappropriate.
        </li>
        <li>
            CELLR may not be used in a manner that is harmful to others.
        </li>
        <li>
            You may not attempt to gain unauthorized access to any portion of the site.
        </li>
        <li>
            Use of automatic query software is prohibited.
        </li>
        <li>
            For the purpose of confirming your compliance with these Terms, CELLR reserves the right to monitor and record activity on the website and app, and reserves the right to remove users who violate this policy.
        </li>
    </ul>
  </Fade>
)

export default AcceptableUsePolicy;

