import React from "react";
import Fade from 'react-reveal/Fade';

const CookiesPolicy = () => (
  <Fade>
    <p>Cookies Policy</p>
    <p>
        In common with most other websites, we use cookies and similar technologies to help understand how people use CELLR
        and to help you get the best results from our services.
        Cookies are small text files that help to recognize you when you return to CELLR,
        and to remember settings such as which currency you prefer to use.
        Similar technologies such as mobile device identifiers and SDK integrations help recognize your device when
        you return to our app or otherwise use the services. By continuing to use the CELLR platform you indicate
        your consent to receive all cookies and similar technologies from CELLR or future service providers we may
        engage with to collect customer feedback.
    </p>
  </Fade>
)

export default CookiesPolicy;

