import React from "react";
import styles from "./copyright-policy.module.scss"
import Fade from 'react-reveal/Fade';

const CopyrightPolicy = () => (
  <Fade>
    <p>Copyright Policy</p>
    <ul className={styles.info}>
        <li>
            All materials on CELLR and <a href="https://www.cellr.com">www.cellr.com</a> are the intellectual
            property of Cellar Ventures, Inc., unless otherwise indicated. Without explicit written permission from us,
            it is illegal to reproduce, modify or store any part of our content (text or image) for any reason other
            than personal, non-commercial use. All CELLR content is protected by international copyright law.
        </li>
    </ul>
    <p>
        CELLR operates in a changing regulatory and technological environment;
        accordingly CELLR may amend these policies from time to time.
        Occasionally, we may need to make urgent changes. We reserve the right to amend this policy at any time,
        and without notice.
        If you have any questions please contact us at <a href="mailto:admin@cellarventures.com">admin@cellarventures.com</a>
    </p>
  </Fade>
)

export default CopyrightPolicy;

